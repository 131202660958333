<template>
  <div class="login-page">
    <div class="title">
      请设置新的密码
    </div>
    <div class="light-color size26 mb68">
      请确认两次输入的密码一致
    </div>
    <van-form @submit="onSubmit">
      <label class="form-label">密码</label>
      <van-field v-model="password"
                 type="password"
                 placeholder="请输入新的密码"
      />
      <label class="form-label">重复密码</label>
      <van-field v-model="rePassword"
                 type="password"
                 placeholder="请再次输入新的密码"
      />
      <div class="btn-box">
        <van-button round block class="save-btn" :disabled="disabled">
          确认
        </van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import { userModel } from '@/api/user'
export default {
  name: 'SetPassword',
  data() {
    return {
      password: '',
      rePassword: ''
    }
  },
  computed: {
    disabled() {
      return !(this.password && this.rePassword)
    }
  },
  mounted() {

  },
  methods: {
    onSubmit() {
      let params={
        "captcha": this.$route.query.code,
        "password": this.password,
        "passwordAgain": this.rePassword,
        "phone": this.$route.query.phone
      }
      userModel.forgetPwd(params).then(()=>{
        this.$dialog.alert({
          message: '新密码设置成功，请重新登录'
        }).then(() => {
          this.$router.push('/login')
        })
      })
    }
  }
}
</script>
<style scoped lang="less">
@import "../../../assets/styles/variable.less";
.form-label{
  display: block;
  margin-top: 40px;
  color: @dark-font-color;
}
.van-cell{
  padding-left: 0;
  padding-right: 0;
  &::after{
    border-bottom: 0;
  }
}
.mb68{
  margin-bottom: 68px;
}
.van-field{
  border-bottom: 1px solid #ddd;
}
.save-btn{
  width: 686px;
  height: 80px;
  font-size: 30px;
  color: #ffffff;
  background: #3A8AE5;
}
.login-page{
  padding: 0 32px;
  .title{
    margin-top:60px;
    margin-bottom: 24px;
    font-size: 36px;
    font-weight: bold;
    color: @dark-font-color;
  }
  .form{
    margin-top: 120px;
    .form-item{
      margin-top: 50px;
      .label{
        font-size: 30px;
        font-weight: bold;
        color: @dark-font-color;
      }
      .input{
        position: relative;
      }
    }

  }
  .btn-box{
    margin: 70px 0 24px;
    .disable{
      background: #B1D3FA;
    }
  }
}
</style>
